<template>
  <div class="about">

    <script v-html=navigationJsonLd() type="application/ld+json"/>

    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title has-text-centered is-size-1" style="font-family: 'Kaushan Script';">
            Über uns
          </h1>
          <h2 class="subtitle is-italic has-text-centered">
            Er aber sprach: Selig sind, die das Wort Gottes hören und bewahren. Lukas 11,28
          </h2>
        </div>
      </div>
    </section>


    <section class="section">
      <div class="container">
        <h1 class="title has-text-centered">Die Hauptsache</h1>
        <p class="content has-text-left">
          ...ist, dass die Hauptsache die Hauptsache bleibt und die ist keine Sache, sondern eine Person:
          Jesus Christus. Warum? Weil es ohne ihn diese Gemeinde schlicht und ergreifend nicht gäbe. Wir
          verstehen uns nicht in erster Linie als Teil einer christlichen Religionsgemeinschaft (und derer
          gibt es ziemlich viele – siehe Wikipedia), sondern als Gemeinschaft von Menschen, für die Jesus mehr
          ist als eine historische Person, ein Religionsstifter oder einfach nur ein guter Mensch. Wir
          glauben, dass Jesus Christus Gottes Sohn ist. Er ist für uns der Dreh- und Angelpunkt sowohl der
          Weltgeschichte als auch unserer persönlichen Geschichte. Dafür stehen wir und das bezeugen wir.

          Und so freuen wir uns über jeden Menschen, der sich voller Skepsis anschauen möchte, was es heißt,
          mit diesem Jesus zu leben. Denn dafür sind wir da.

          Herzlich willkommen!

        </p>
        <h1 class="title has-text-centered">Persönliche Geschichten</h1>
        <div class="content">
          <carousel :perPageCustom="[[320, 1], [1199, 3]]" :autoplay="true" :autoplayTimeout="30000"
                    :navigationEnabled="true" :loop="true">
            <slide>
              <Zeugnis
                  :name="'Dominik, '+getAge('1998-04-26')+' Jahre'"
                  beschreibung="Ich glaube an Jesus, weil er stellvertretend für mich am Kreuz für meine Sünden gestorben ist. Er hat mir meine Schuld vergeben und schenkt mir ewiges Leben. Ich darf wissen, dass er immer bei mir ist und mir in jeder Situation hilft, auch wenn ich ihn nicht sehe. Durch dieses Vertrauen auf Jesus habe ich schon oft erkennen dürfen, wie er handelt und wirkt, vor allem in meinem Freiwilligendienst in Amerika.
                In der CG Karlsruhe höre ich viel über den Glauben an Jesus, was mir in meinem persönlichen Leben hilft. Außerdem fühle ich mich dort wohl und habe gute Beziehungen zu den Leuten. Hier können Menschen, die Fragen haben, Kontakte zu Gläubigen knüpfen und nachfragen, wie diese ihren Glauben an Gott im Alltag leben."
                  image="ansprechpartner/dominik.jpg"></Zeugnis>
            </slide>
            <slide>
              <Zeugnis
                  :name="'Anke, '+getAge('1969-06-12')+' Jahre'"
                  beschreibung="Jesus ist derjenige, der meinem Leben Halt und Anker gibt. Er ist nicht vergänglich wie alles andere im Leben. Und er liebt mich und hält zu mir, obwohl er mich ganz genau kennt. Ich bin in dieser Gemeinde vor 28 Jahren zum Glauben gekommen und sie ist mir seither zur zweiten Familie geworden, in der ich mich wohlfühle und wo ich meine Begabungen einbringen kann. Menschen, die auf der Suche sind, empfehle ich, die Bibel unvoreingenommen zu lesen und sie konkret als Reden Gottes ganz persönlich zu verstehen; und persönliche Kontakte zu Leuten zu pflegen, die schon länger mit Jesus unterwegs sind."
                  image="ansprechpartner/anke.jpg"></Zeugnis>
            </slide>
            <slide>
              <Zeugnis
                  :name="'Jutta, '+getAge('1956-04-15')+' Jahre'"
                  beschreibung="Warum ich an Jesus glaube? Weil ich ihn persönlich kennengelernt habe. Ich habe schon 1972 von ihm gehört, aber erst seit August 2014 ist er für mich mein Herr und Erlöser.
                            Jesus gibt mir Geborgenheit und die Zuversicht, dass er mich hält und leitet. Die CG Karlsruhe ist für mich zur Heimat geworden ist. Ich brauche die Gemeinde, bzw. die Gemeinschaft mit Gläubigen. Skeptischen Menschen kann ich nur sagen, dass es ein guter Weg ist, Ja zu Jesus zu sagen. Ich würde diesen Weg immer wieder gehen, da er mir innere Ruhe schenkt."
                  image="ansprechpartner/jutta.jpg"></Zeugnis>
            </slide>
            <slide>
              <Zeugnis
                  :name="'Willi, '+getAge('1951-10-9')+' Jahre'"
                  beschreibung="Ich habe mein Leben mit 14 Jahren bewusst in Gottes Hände gelegt. Seitdem lebe ich zusammen mit meiner lieben Frau eine immer tiefere Beziehung mit Jesus Christus und bin so dankbar, dass auch unsere Kinder ihren Lebensweg mit ihm gehen. Doch auch mit Gott gerät man in Krisen: 2004 und weiteren Folgejahren litt ich unter Burnout und habe eine tiefe Lebenskrise durchlebt. Als Leiter einer größeren Bankfiliale haben mich immer mehr Schlaflosigkeit und Versagensängste in eine Depression gestürzt, die mich unfähig machte, meinen Beruf auszuüben. Wo war jetzt Gott,  als es mir in diesem Abgrund so dreckig ging, ohne Aussicht, dass mein Leben je wieder in Bahnen kommt und ich meine Familie versorgen könnte? Es waren lange Monate, bis ich nach und nach verstand, dass wir als Christen nicht in einer Sänfte in den Himmel getragen werden. Christen haben nicht die Zusage auf lebenslange ungetrübte Glückseligkeit hier auf der Erde bekommen. Gott hat mir mit großer Geduld beigebracht, dass er in den Tiefen des Lebens, in Krankheit und Not genauso an meiner Seite ist. Er trägt mich auch da liebevoll und will mir lebenswichtige Lektionen beibringen, die meinen Charakter mehr in seine Wesensart verändern sollen. Gerade in dieser dunklen Zeit waren mir neben meiner Familie auch die Leute meine Gemeinde eine große Hilfe mit ihren Ermutigungen, Gebeten und liebevollen Zuwendung.  Ich durfte Heilung erfahren, konnte wieder arbeiten und kann jetzt eine Hilfe sein für andere, die auf ähnliche Wege geführt werden.
                            Jesus Christus, der aus Liebe sein Leben für mich gab, habe ich auf diese Weise neu und tiefer kennen und lieben gelernt. Er ist und bleibt mein guter Hirte, auch im dunklen Tal. Selbst der Tod verliert seine Schrecken, weil dieses Leben nicht alles ist, sondern ich hier und drüben für immer in seinen Händen bin."
                  image="ansprechpartner/willi_b.jpg"></Zeugnis>
            </slide>
          </carousel>

        </div>

        <h1 class="title has-text-centered">Das Glaubensbekenntnis</h1>
        <h2 class="title is-4">GOTT</h2>

        <p class="content has-text-left">
          Wir glauben, dass es nur einen Gott gibt, der sich uns als dreieiniger Gott als Vater, Sohn und
          Heiliger Geist offenbart. Er ist der einzige, wahre und lebendige Gott. Sein Wesen ist vollkommen
          gut, gerecht, heilig und liebevoll. Er ist der Schöpfer und Erhalter dieser Welt, ohne ihn gäbe es
          kein Leben.<br/>
          Er allein verdient unsere Anbetung.
        </p>

        <h1 class="title is-4">DIE BIBEL</h1>

        <p class="content has-text-left">
          Wir glauben, dass sich Gott in und durch die Bibel den Menschen offenbart hat. Sie ist durch die
          Hand von Menschen entstanden, aber sie ist von seinem Heiligen Geist inspiriert und trägt seine
          Handschrift. Wir halten sie für absolut zuverlässig und wahr in allem, was sie lehrt. Sie ist
          deshalb für uns letzte Autorität in allen Lebensfragen, sowohl als Gemeinde als auch als
          Einzelpersonen.<br/><br/>

          Nicht alles in der Bibel ist einfach zu verstehen, aber dennoch glauben wir, dass wir darin Gottes
          offenbarte Wahrheit durch seinen Geist im Wesentlichen klar erkennen können.
        </p>

        <h1 class="title is-4">EIN NEUANFANG IST NÖTIG</h1>

        <p class="content has-text-left">
          Gott hat uns Menschen in seinem Ebenbild erschaffen, damit wir seine Größe, Liebe und
          Einzigartigkeit widerspiegeln. Als seine Geschöpfe sind wir in Abhängigkeit von Gott geschaffen und
          brauchen ihn zum Leben.<br/><br/>

          Dennoch haben wir uns von Gott abgewandt, unsere Abhängigkeit verneint und haben uns selbst zum
          Herrn unseres Lebens gemacht. Statt von Gott, erwarten wir von geschaffenen Dingen oder durch eigene
          Anstrengung Glück, Sicherheit und Zufriedenheit. Durch diese Rebellion gegen unseren Schöpfer sind
          wir Menschen vor Gott schuldig geworden. In dieser Lage können wir von dem gerechten Gott nur Strafe
          erwarten.<br/><br/>

          Was wir deshalb vor allem anderen brauchen, ist mit unserem Gott versöhnt zu werden. Worauf wir
          einzig hoffen können, ist die unverdiente Liebe dieses Gottes, der uns allein retten und die
          Beziehung zu ihm wiederherstellen kann.
        </p>

        <h1 class="title is-4">EIN NEUANFANG IST MÖGLICH IN JESUS CHRISTUS</h1>
        <p class="content has-text-left">
          Wir glauben, dass sich die unverdiente Liebe Gottes allein in Jesus Christus zeigt. Er ist Gott und
          wurde von einer Jungfrau als Mensch geboren. Er lebte das Leben, das wir hätten leben sollen. Er
          wurde verurteilt, am Kreuz hingerichtet und begraben. So trug er die Schuld und Strafe, die wir
          hätten tragen müssen. Wir glauben, dass er auferstanden und zu Gott, dem Vater, zurückgekehrt ist.
          So hat begonnen, was eines Tages vollendet wird: Er wird wiederkommen und Gottes Plan mit dieser
          Welt zu seinem endgültigen Ziel führen.<br/><br/>

          Dadurch besteht nun für jeden Menschen die Möglichkeit zur Versöhnung mit Gott. Das geschieht, indem
          wir Jesus um Vergebung unserer Schuld bitten und ihn wieder den rechtmäßigen Platz in unserem Leben
          als Herrn geben. Und dass ein Mensch diese Wahrheit versteht – das ist Gnade Gottes.
        </p>

        <p class="zitat has-text-centered">
          Bei niemand anderem ist Rettung zu finden; unter dem ganzen Himmel ist uns Menschen kein anderer Name gegeben,
          durch den wir gerettet werden können.
          <br/>
          <span class="zitat_quelle">Apg 4,12</span>
        </p>


        <h1 class="title is-4">DIE KRAFT DES HEILIGEN GEISTES</h1>
        <p class="content has-text-left">Wir glauben, dass der Heilige Geist Menschen die Augen öffnet sowohl für die
          Notwendigkeit als auch für die Möglichkeit dieses Neuanfangs. Wer so durch den Glauben an Jesus Christus
          Vergebung erfährt, den befähigt der Heilige Geist zu einem Leben, das Gottes Willen entspricht, ihn ehrt und
          verherrlicht.
          <br/><br/>
          Er überführt uns von Sünde, prägt unser Leben und erneuert unseren Charakter in Übereinstimmung mit Gottes
          Maßstäben. Er rüstet uns aus, damit wir an Gottes Plan mit dieser Welt mitarbeiten können und seine Größe,
          Liebe, Einzigartigkeit in Wort und Tat bekannt machen.

        </p>


        <h1 class="title is-4">DIE GEMEINDE JESU</h1>
        <p class="content has-text-left">Alle, die an Jesus Christus glauben und es öffentlich bekennen, gehören zur
          weltweiten Gemeinde Jesu. Greifbar wird das durch die Zugehörigkeit zu einer örtlichen christlichen Kirche
          oder Gemeinschaft. Wir glauben, dass es als Christen keine Einzelkämpfer gibt, sondern Gott uns als seine
          Kinder zusammengestellt hat, damit wir aufeinander hören, einander helfen und unterstützen.
          <br/><br/>
          Als Gemeinde Gottes sind wir aber keine geschlossene Gesellschaft, sondern wir laden in unsere Gemeinschaft
          ein und wollen ein Ort sein, wo Gott und sein Wesen durch uns selbst, unsere Worte und unsere Taten sichtbar
          werden.


        </p>

        <h1 class="title is-4">DIE TAUFE UND DAS ABENDMAHL</h1>
        <p class="content has-text-left">
          Wir sind überzeugt, dass jeder, der an Jesus Christus, als Retter und Herrn glaubt, sich taufen lassen soll.
          Dadurch wird symbolisch bezeugt, dass sein altes Leben „begraben“ wurde, neues Leben begonnen hat und man nun
          Teil der Gemeinde Jesu ist.
          <br/><br/>
          Wir glauben an die Bedeutung des „Abendmahls“ als ganzheitliche „Predigt“ von Jesu Tod, als Erinnerung an sein
          Sterben an unsrer Stelle, als Ausdruck der Gemeinschaft aller, die zu ihm gehören, und feiern es in der
          Erwartung, dass Jesus eines Tages wiederkommt.

        </p>

        <h1 class="title is-4">DAS GERICHT GOTTES UND DIE ERNEUERUNG DER SCHÖPFUNG</h1>
        <p class="content has-text-left">
          Wir glauben, dass Jesus eines Tages wiederkommt, um Gottes Plan mit dieser Welt zu seinem endgültigen Ziel zu
          führen. Wir glauben an eine leibliche Auferstehung aller Menschen zum Gericht. Wer sich gegen Gottes Anspruch
          auf sein Leben und gegen Gottes Vergebung sperrt, den erwartet ewige Trennung von Gott (so hat es Jesus selbst
          gelehrt) – ein Zustand, den die Bibel als grausam und unerträglich beschreibt – die Hölle.
          <br/><br/>
          Die aber, die ihre Schuld vor Gott bekannt und ihr Leben Jesus als ihrem Retter und Herrn anvertraut haben,
          spricht Gott frei und schenkt ihnen Vergebung. Sie werden für immer in einer erneuerten Schöpfung in seiner
          Nähe bei ihm leben und die Schönheit und Einzigartigkeit ihres Schöpfers endlich vollkommen widerspiegeln.

        </p>
        <h1 class="title has-text-centered">Die Gemeindeleitung</h1>

        <div class="content">
          <div class="columns">
            <div class="column">
              <Aelteste
                  vorname="Andreas"
                  name="Herburger"
                  empfaenger="andreas_herburger"
                  image="ansprechpartner/andreas_h.jpg"></Aelteste>
            </div>
            <div class="column">
              <Aelteste
                  vorname="Christian"
                  name="Hirsch"
                  empfaenger="christian_hirsch"
                  image="ansprechpartner/christian_h.jpg"></Aelteste>
            </div>
            <div class="column">
              <Aelteste
                  vorname="Otto"
                  name="Hubenschmid"
                  empfaenger="otto_h"
                  image="ansprechpartner/otto.jpg"></Aelteste>
            </div>
            <div class="column">
              <Aelteste
                  vorname="Markus"
                  name="Remmel"
                  empfaenger="markus_remmel"
                  image="ansprechpartner/markus_r.jpg"></Aelteste>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Zeugnis from "../components/Zeugnis";
import Aelteste from "../components/Aelteste";
import {Carousel, Slide} from 'vue-carousel';
import moment from 'moment'

export default {
  components: {
    Zeugnis, Aelteste, Carousel,
    Slide
  },
  data() {
    return {
      carousel: 0,
      animated: 'fade',
      drag: false,
      autoPlay: false,
      pauseHover: false,
      pauseInfo: false,
      repeat: false,
      pauseType: 'is-primary',
      interval: 3000,
    }
  },
  methods: {
    getAge: function (year) {
      return moment().diff(year, 'years');
    },
    navigationJsonLd() {
      var jsonLdBreadcrumb= {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "CG Karlsruhe",
          "item": "https://www.cg-karlsruhe.de"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Über uns",
          "item": "https://www.cg-karlsruhe.de/about"
        }]
      };
      return jsonLdBreadcrumb;
    }
  }
}
</script>


<style>
p.zitat {
  padding-right: 15%;
  padding-left: 15%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Lato', sans-serif;
  font-size: 15pt;
  color: #58595b;
  font-weight: 300;
  font-style: italic;
}


span.zitat_quelle {
  color: #faa61a;
  font-size: 15pt;
  font-weight: 300;
  font-style: italic;
}
</style>